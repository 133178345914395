import { ENABLE_CUSTOM_FLOOR_LAYOUT_DATA } from '@/utils/constant'

export const tableOption = {
  search: true,
  height: '100%',
  menuWidth: 150,
  menuAlign: 'center',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSpan: 6,
  selection: false,
  searchLabelWidth: 90,
  column: [
    {
      label: '布局样式',
      prop: 'displayImagePath',
      slot: true
    },
    {
      label: '状态',
      prop: 'enable',
      search: true,
      type: 'select',
      dicData: ENABLE_CUSTOM_FLOOR_LAYOUT_DATA
    },
    {
      prop: 'create_time',
      search: true,
      searchSpan: 9,
      searchLabel: '时间',
      searchFormSlot: true,
      hide: true
    },

    {
      label: '时间',
      prop: 'createTime'
    }
  ]
}
